import { useCallback, useState } from "react";
import { twJoin, twMerge } from "tailwind-merge";
import MiniStrengthIndicator from "./MiniStrengthIndicator";
import PerpetratorTable from "./PerpetratorTable";
import FeedbackButtons from "./FeedbackButtons";
import FeedbackStatus from "./FeedbackStatus";
import FeedbackInput from "./FeedbackInput";
import { Feedback, Hit } from "@/types";
import {
  CircleOutlined,
  CircleRounded,
  ContentCopyRounded,
  ContrastRounded,
  DoNotDisturbOnOutlined,
} from "@mui/icons-material";

type RiskCardV3Props = {
  type: "pep" | "adverse_media";
  hit: Hit;
  input: unknown;
  handleSubmitFeedback: (isValid: boolean, rowId: string, comment: string) => Promise<void>;
  feedback?: Feedback;
  expandByDefault: boolean;
  isLoadingFeedback: boolean;
  renderContent: () => React.ReactNode;
};

const RiskCardV3 = ({
  type,
  hit,
  input,
  handleSubmitFeedback,
  feedback,
  expandByDefault,
  isLoadingFeedback,
  renderContent,
}: RiskCardV3Props) => {
  const [showEventInfo, setShowEventInfo] = useState(expandByDefault);
  const [showFeedbackComment, setShowFeedbackComment] = useState(false);
  const [flaggedSelected, setFlaggedSelected] = useState(false);
  const [feedbackComment, setFeedbackComment] = useState("");

  const [showToast, setShowToast] = useState(false);

  const isStrongMatch = hit.profile_review.match_rating.match === "strong_match";
  const isPartialMatch = hit.profile_review.match_rating.match === "partial_match";

  const rowId = hit.reference_id ?? hit.id;

  const onSubmitFeedbackClick = async () => {
    await handleSubmitFeedback(false, rowId ?? "", feedbackComment);
  };

  const handleCopyClick = useCallback(
    (e: React.MouseEvent) => {
      e.stopPropagation();
      const idToCopy = rowId?.split("_").at(-1) ?? "";
      navigator.clipboard.writeText(idToCopy);
      setShowToast(true);
      setTimeout(() => setShowToast(false), 3000);
    },
    [rowId],
  );

  const firstNameMatch = hit.profile_review.first_name_match;
  const middleNameMatch = hit.profile_review.middle_name_match;
  const lastNameMatch = hit.profile_review.last_name_match;
  const ageMatch = hit.profile_review.age_match;
  const countryMatch = hit.profile_review.country_match;

  return (
    <div className={twJoin("flex flex-col overflow-hidden", showEventInfo ? "rounded-t-lg" : "rounded-lg")}>
      {/* Card Header */}
      <div
        className={twMerge(
          "flex border border-transparent justify-between py-3 pr-3 pl-5 flex-wrap lg:flex-nowrap gap-x-5 gap-y-4 group w-full",
          isStrongMatch ? "bg-red-50" : isPartialMatch ? "bg-white" : "bg-slate-50",
          feedback?.score === false ? "items-baseline" : "items-center",
          showEventInfo || showFeedbackComment || flaggedSelected ? "rounded-t-lg border-b-0" : "rounded-lg",
          !showEventInfo
            ? isStrongMatch
              ? "hover:border-red-200"
              : isPartialMatch
                ? "border-slate-200 hover:border-slate-300"
                : "hover:border-slate-300"
            : isStrongMatch
              ? "hover:border-red-200"
              : "border-slate-200 hover:border-slate-300",
        )}
        role="button"
        onClick={() => setShowEventInfo(!showEventInfo)}
      >
        <div className="flex items-center w-full gap-x-5">
          <div className="flex items-center gap-x-2 flex-nowrap font-semibold text-sm">
            {firstNameMatch?.name && (
              <span className="flex items-center gap-x-1">
                {firstNameMatch.name}{" "}
                {firstNameMatch.exact_match ? (
                  <CircleRounded sx={{ fontSize: "0.625rem" }} className="text-red-600" />
                ) : firstNameMatch.partial_match ? (
                  <ContrastRounded sx={{ fontSize: "0.625rem" }} className="text-orange-600" />
                ) : firstNameMatch.mismatch ? (
                  <CircleOutlined sx={{ fontSize: "0.625rem" }} className="text-yellow-700" />
                ) : null}
              </span>
            )}
            {middleNameMatch?.name && (
              <span className="flex items-center gap-x-1">
                {middleNameMatch.name}
                {middleNameMatch.exact_match ? (
                  <CircleRounded sx={{ fontSize: "0.625rem" }} className="text-red-600" />
                ) : middleNameMatch.partial_match ? (
                  <ContrastRounded sx={{ fontSize: "0.625rem" }} className="text-orange-600" />
                ) : middleNameMatch.mismatch ? (
                  <CircleOutlined sx={{ fontSize: "0.625rem" }} className="text-slate-500" />
                ) : null}
              </span>
            )}
            {lastNameMatch?.name && (
              <span className="flex items-center gap-x-1">
                {lastNameMatch.name}
                {lastNameMatch.exact_match ? (
                  <CircleRounded sx={{ fontSize: "0.625rem" }} className="text-red-600" />
                ) : lastNameMatch.partial_match ? (
                  <ContrastRounded sx={{ fontSize: "0.625rem" }} className="text-orange-600" />
                ) : lastNameMatch.mismatch ? (
                  <CircleOutlined sx={{ fontSize: "0.625rem" }} className="text-slate-500" />
                ) : null}
              </span>
            )}
          </div>
          <span
            className={twJoin(
              "text-xs flex items-center gap-x-1",
              !ageMatch.age ? "italic text-slate-400" : "text-slate-700 ",
            )}
          >
            {ageMatch.age ? `${ageMatch.age} years old` : "Age Not Found"}
            {ageMatch.exact_match ? (
              <CircleRounded sx={{ fontSize: "0.625rem" }} className="text-red-600" />
            ) : ageMatch.partial_match ? (
              <ContrastRounded sx={{ fontSize: "0.625rem" }} className="text-orange-600" />
            ) : ageMatch.mismatch ? (
              <DoNotDisturbOnOutlined sx={{ fontSize: "0.625rem" }} className="text-yellow-700" />
            ) : null}
          </span>
          {countryMatch.countries && countryMatch.countries.length > 0 ? (
            <span className="text-xs text-slate-700 flex items-center gap-x-1 truncate">
              {countryMatch.countries
                .map((country: any) =>
                  country !== null && typeof country === "object" && "alpha_2_country_code" in country
                    ? country.alpha_2_country_code
                    : country,
                )
                .join(", ")}
              {countryMatch.exact_match ? (
                <CircleRounded sx={{ fontSize: "0.625rem" }} className="text-red-600" />
              ) : countryMatch.partial_match ? (
                <ContrastRounded sx={{ fontSize: "0.625rem" }} className="text-orange-600" />
              ) : countryMatch.mismatch ? (
                <DoNotDisturbOnOutlined sx={{ fontSize: "0.625rem" }} className="text-yellow-700" />
              ) : !countryMatch.countries || countryMatch.countries.length === 0 ? (
                <CircleOutlined sx={{ fontSize: "0.625rem" }} className="text-slate-500" />
              ) : null}
            </span>
          ) : (
            <span className="text-xs italic text-slate-400">Location Not Found</span>
          )}
        </div>
        {/* Feedback Controls */}
        <div className="flex flex-col w-fit items-end gap-2 shrink-0">
          <div className="flex items-center gap-x-5 w-fit text-xs flex-1 shrink-0">
            {hit.profile_review.match_rating.match !== "no_match" && (
              <MiniStrengthIndicator matchRating={hit.profile_review.match_rating.match} />
            )}

            {/* Feedback Buttons */}
            <FeedbackButtons
              isLoadingFeedback={isLoadingFeedback}
              isFail={isStrongMatch}
              flaggedSelected={flaggedSelected}
              setFlaggedSelected={setFlaggedSelected}
              feedback={feedback}
              handleSubmitFeedback={handleSubmitFeedback}
              rowId={rowId ?? ""}
              setFeedbackComment={setFeedbackComment}
              setShowFeedbackComment={setShowFeedbackComment}
            />
          </div>

          {/* Feedback Status */}
          {!isLoadingFeedback && feedback && feedback.score === false && (
            <FeedbackStatus
              feedback={feedback}
              showEventInfo={showEventInfo}
              showFeedbackComment={showFeedbackComment}
              setShowFeedbackComment={setShowFeedbackComment}
              flaggedSelected={flaggedSelected}
              setFlaggedSelected={setFlaggedSelected}
              setFeedbackComment={setFeedbackComment}
            />
          )}
        </div>
        <div
          className={twJoin(
            "min-w-[6rem] w-fit text-xs text-slate-700 w-fit flex flex-col",
            feedback?.score === false ? "self-baseline" : "self-end",
          )}
        >
          <span className="font-semibold truncate">Vendor ID</span>

          <button
            onClick={handleCopyClick}
            className="relative inline-flex gap-1 items-center w-fit hover:text-brand-purple transition-colors"
            title="Copy to clipboard"
          >
            <span className="truncate text-sm">{rowId?.split("_").at(-1)}</span>
            <ContentCopyRounded sx={{ fontSize: "1rem" }} />
          </button>
          {/* Toast Notification */}
          {showToast && (
            <div className="fixed top-5 left-1/2 transform -translate-x-1/2 -translate-y-1/2 bg-brand-purple text-white px-4 py-2 rounded-md z-50 animate-fade-in-out w-fit truncate">
              Vendor ID copied to clipboard
            </div>
          )}
        </div>
      </div>

      {/* Feedback Input */}
      {(flaggedSelected || showFeedbackComment) && (
        <FeedbackInput
          matchRating={hit.profile_review.match_rating.match}
          showEventInfo={showEventInfo}
          feedbackComment={feedbackComment}
          setFeedbackComment={setFeedbackComment}
          feedback={feedback}
          showFeedbackComment={showFeedbackComment}
          setShowFeedbackComment={setShowFeedbackComment}
          flaggedSelected={flaggedSelected}
          setFlaggedSelected={setFlaggedSelected}
          onSubmitFeedbackClick={onSubmitFeedbackClick}
        />
      )}

      {/* Content Section */}
      {showEventInfo && (
        <div className="flex flex-col gap-y-2 border-x border-b rounded-b-lg border-slate-200">
          <PerpetratorTable
            mediaHit={hit}
            input={input}
            hitTableLabel={type === "pep" ? "Found PEP" : "Found Perpetrator"}
          />
          <div className="px-5 pt-2.5">{renderContent()}</div>
        </div>
      )}
    </div>
  );
};

export default RiskCardV3;
