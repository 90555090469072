import { useEffect, useState } from "react";
import { Worker, Viewer } from "@react-pdf-viewer/core";
import { thumbnailPlugin, RenderThumbnailItemProps } from "@react-pdf-viewer/thumbnail";
import { zoomPlugin } from "@react-pdf-viewer/zoom";
import { pageNavigationPlugin } from "@react-pdf-viewer/page-navigation";
import { fullScreenPlugin } from "@react-pdf-viewer/full-screen";
import CheckStatusMessage from "./CheckStatusMessage";
import { isAddressProvided, snakeCaseToProperCase } from "@/utils";

// Import CSS styles for the plugins
import "@react-pdf-viewer/thumbnail/lib/styles/index.css";
import "@react-pdf-viewer/zoom/lib/styles/index.css";
import "@react-pdf-viewer/page-navigation/lib/styles/index.css";
import "@react-pdf-viewer/full-screen/lib/styles/index.css";

import {
  CancelRounded,
  CheckCircleRounded,
  ExpandLessRounded,
  ExpandMoreRounded,
  ZoomOutRounded,
  ZoomInRounded,
  FullscreenRounded,
  LaunchRounded,
  ErrorRounded,
  MailRounded,
  EmailRounded,
  WarningRounded,
  InfoRounded,
} from "@mui/icons-material";
import AddressValue from "./AddressValue";
import WrappedValue from "./WrappedValue";
import InfoValue from "./InfoValue";
import Skeleton from "./Skeleton";
import { useParchaApi } from "@/hooks/useParchaApi";
import {
  BUSINESS_OWNERSHIP_CHECK_ID,
  INCORPORATION_DOCUMENT_CHECK_ID,
  PROOF_OF_ADDRESS_CHECK_ID,
  EIN_DOCUMENT_CHECK_ID,
  SOURCE_OF_FUNDS_CHECK_ID,
  KYC_PROOF_OF_ADDRESS_CHECK_ID,
  shimmerEffectClassNames,
} from "@/constants/vars";
import { format } from "date-fns";
import { twJoin } from "tailwind-merge";
import FullWidthTabs from "./FullWidthTabs";
import { snakeCase } from "lodash";
const DocumentValidation = ({
  agentInstanceId,
  agentType,
  checkId,
  checkName,
  documentName,
  documentUrl,
  endpointUrl,
  jobId,
}: any) => {
  const [showContent, setShowContent] = useState<boolean>(true);
  const [showVisualAnalysis, setShowVisualAnalysis] = useState<boolean>(true);
  const [showFraudAndTampering, setShowFraudAndTampering] = useState<boolean>(true);

  const [checkResult, setCheckResult] = useState<any>(null);
  const [showAnswer, setShowAnswer] = useState<boolean>(false);
  const [pdfError, setPdfError] = useState<boolean>(false);
  const [selectedTab, setSelectedTab] = useState<string>("found-information");
  const parchaApi = useParchaApi();

  const thumbnailPluginInstance = thumbnailPlugin();
  const zoomPluginInstance = zoomPlugin();
  const pageNavigationPluginInstance = pageNavigationPlugin();
  const fullScreenPluginInstance = fullScreenPlugin();

  const { ZoomIn, ZoomOut } = zoomPluginInstance;
  const { Thumbnails } = thumbnailPluginInstance;
  const { CurrentPageLabel, NumberOfPages } = pageNavigationPluginInstance;
  const { EnterFullScreen } = fullScreenPluginInstance;

  useEffect(() => {
    parchaApi
      .getCheckResultFromJob(endpointUrl, jobId, checkId, agentInstanceId, agentType, true)
      .then((checkResult) => {
        setCheckResult(checkResult);
        if (checkResult.passed !== null && !checkResult.passed) {
          setShowAnswer(true);
        }
      });
    const interval = setInterval(() => {
      parchaApi
        .getCheckResultFromJob(endpointUrl, jobId, checkId, agentInstanceId, agentType, true)
        .then((checkResult) => {
          setCheckResult(checkResult);

          if (checkResult.passed !== null && !checkResult.passed) {
            setShowAnswer(true);
          }

          if (["error", "complete"].includes(checkResult.status)) {
            clearInterval(interval);
          }
        });
    }, 2000);

    return () => clearInterval(interval);
  }, [jobId, agentInstanceId, checkId]);

  if (!checkResult) return null;

  const renderThumbnailItem = (props: RenderThumbnailItemProps) => {
    const { pageIndex, currentPage, onJumpToPage, key } = props;
    return (
      <div
        key={key}
        className={`w-fit h-fit mb-4 ${
          currentPage === pageIndex ? "border-2 border-solid border-brand-purple" : ""
        } hover:cursor-pointer`}
        onClick={onJumpToPage}
      >
        {props.renderPageThumbnail}
      </div>
    );
  };

  type Value = {
    value: any;
    label: string;
    type: ValueType;
    level?: "success" | "failure";
  };

  type ValueType =
    | "text"
    | "wrapped"
    | "address"
    | "validation_entries"
    | "doc_fraud_verification"
    | "doc_visual_inspection_result"
    | "visual_inspection_result"
    | "owner_info"
    | "summary";

  const getValues = () => {
    let contentValues: Value[] = [];
    let visualAnalysisValues: Value[] = [];
    let fraudAndTamperingValues: Value[] = [];

    switch (checkId) {
      case INCORPORATION_DOCUMENT_CHECK_ID:
        const combinedIncorporationDocuments = [
          ...(checkResult.check_result?.verified_incorporation_documents || []),
          ...(checkResult.check_result?.valid_documents || []),
          ...(checkResult.check_result?.invalid_documents || []),
        ];

        const incorporationDocument =
          combinedIncorporationDocuments.length > 0 ? combinedIncorporationDocuments[0] : null;

        contentValues = [
          {
            value:
              checkResult.check_result?.verified_business_name ||
              incorporationDocument?.business_name ||
              incorporationDocument?.extraction_data?.business_name,
            label: "Business Name",
            type: "text",
          },
          {
            value: checkResult.check_result?.verified_incorporation_date
              ? format(
                  new Date(checkResult.check_result.verified_incorporation_date?.replace(/-/g, "/")),
                  "MMMM dd, yyyy",
                )
              : incorporationDocument?.incorporation_date || incorporationDocument?.extraction_data?.incorporation_date
                ? format(
                    new Date(
                      (
                        incorporationDocument.incorporation_date ||
                        incorporationDocument.extraction_data.incorporation_date
                      )?.replace(/-/g, "/"),
                    ),
                    "MMMM dd, yyyy",
                  )
                : null,
            label: "Incorporation Date",
            type: "text",
          },
          {
            value: checkResult.check_result?.verified_registration_number
              ? checkResult.check_result?.verified_registration_number
              : incorporationDocument?.business_registration_number
                ? incorporationDocument?.business_registration_number?.join(", ")
                : incorporationDocument?.extraction_data?.business_registration_number
                  ? incorporationDocument.extraction_data.business_registration_number.join(", ")
                  : null,
            label: "Registration Number",
            type: checkResult.check_result?.verified_registration_number ? "wrapped" : "text",
          },
          {
            value: isAddressProvided(checkResult.check_result?.verified_business_address)
              ? checkResult.check_result?.verified_business_address
              : isAddressProvided(incorporationDocument?.business_address)
                ? incorporationDocument?.business_address
                : isAddressProvided(incorporationDocument?.extraction_data?.business_address)
                  ? incorporationDocument?.extraction_data?.business_address
                  : null,
            label: "Address of Incorporation",
            type: "address",
          },
          {
            value:
              checkResult.check_result?.verified_business_activity ||
              incorporationDocument?.business_activity ||
              incorporationDocument?.extraction_data?.business_activity,
            label: "Business Activity",
            type: "text",
          },
        ];

        if (incorporationDocument) {
          contentValues.push({
            value: incorporationDocument.summary || incorporationDocument.extraction_data.summary,
            label: "Document Summary",
            type: "text",
          });

          if (incorporationDocument.fraud_verification_data) {
            fraudAndTamperingValues.push({
              label: "Anti-Tampering and Fraud Verification",
              value: {
                verificationDescription: incorporationDocument.fraud_verification_data.verification_description,
                verificationData: incorporationDocument.fraud_verification_data.verification_data,
              },
              type: "doc_fraud_verification",
            });
          }

          if (!incorporationDocument.visual_inspection) break;
          if (incorporationDocument.visual_inspection.type === "VisualInspectionResults") {
            if (!incorporationDocument.visual_inspection.visual_analysis) break;

            visualAnalysisValues.push({
              value: incorporationDocument.visual_inspection.summary,
              label: "Summary",
              type: "summary",
              level: incorporationDocument.visual_inspection.inspection_passed ? "success" : "failure",
            });

            visualAnalysisValues.push({
              value: incorporationDocument.visual_inspection.visual_analysis.seal_verification,
              label: "Official Seal",
              type: "visual_inspection_result",
              level:
                incorporationDocument.visual_inspection.visual_analysis.seal_verification.passed &&
                incorporationDocument.visual_inspection.visual_analysis.seal_verification.present
                  ? "success"
                  : "failure",
            });

            visualAnalysisValues.push({
              value: incorporationDocument.visual_inspection.visual_analysis.signature_verification,
              label: "Signature",
              type: "visual_inspection_result",
              level:
                incorporationDocument.visual_inspection.visual_analysis.signature_verification.passed &&
                incorporationDocument.visual_inspection.visual_analysis.signature_verification.present
                  ? "success"
                  : "failure",
            });
          } else if (
            "official_seal" in incorporationDocument.visual_inspection &&
            "official_signature" in incorporationDocument.visual_inspection
          ) {
            const valuesArray = Object.entries(incorporationDocument.visual_inspection).map(([key, value]) => {
              return {
                label: snakeCaseToProperCase(key),
                value: value,
              };
            });

            visualAnalysisValues.push({
              value: valuesArray,
              label: "Visual Inspection Results",
              type: "validation_entries",
            });
          } else {
            visualAnalysisValues.push({
              value: {
                validStateSeal: incorporationDocument.visual_inspection.seal_valid,
                validSignature: incorporationDocument.visual_inspection.signature_valid,
              },
              label: "Visual Inspection Results",
              type: "doc_visual_inspection_result",
            });
          }
        }
        break;
      case BUSINESS_OWNERSHIP_CHECK_ID:
        contentValues = [
          {
            value: checkResult.check_result?.verified_business_owners,
            label: "Verified Business Owners",
            type: "owner_info",
          },
        ];

        if (checkResult.check_result?.unverified_business_owners) {
          contentValues.push({
            value: checkResult.check_result?.unverified_business_owners,
            label: "Unverified Business Owners",
            type: "owner_info",
          });
        }

        const combinedOwnershipDocuments = [
          ...(checkResult.check_result?.documented_ownership || []),
          ...(checkResult.check_result?.valid_documents || []),
          ...(checkResult.check_result?.invalid_documents || []),
        ];

        const businessOwnershipDocument = combinedOwnershipDocuments.length > 0 ? combinedOwnershipDocuments[0] : null;
        if (businessOwnershipDocument) {
          contentValues.push({
            value: businessOwnershipDocument.summary || businessOwnershipDocument.extraction_data.summary,
            label: "Summary",
            type: "text",
          });
        }
        break;
      case PROOF_OF_ADDRESS_CHECK_ID:
        const combinedProofOfAddressDocuments = checkResult.verified_data
          ? [
              ...(checkResult.check_result?.proof_of_address_documents || []),
              ...(checkResult.check_result?.valid_documents || []),
              ...(checkResult.check_result?.invalid_documents || []),
            ]
          : [];

        const proofOfAddressDocument =
          combinedProofOfAddressDocuments.length > 0 ? combinedProofOfAddressDocuments[0] : null;

        contentValues.push({
          value: isAddressProvided(checkResult.check_result?.verified_address)
            ? checkResult.check_result?.verified_address
            : proofOfAddressDocument?.address,
          label: "Address of Operation",
          type: "address",
        });

        if (proofOfAddressDocument) {
          contentValues.push({
            value: proofOfAddressDocument.summary || proofOfAddressDocument.extraction_data.summary,
            label: "Summary",
            type: "text",
          });

          if (proofOfAddressDocument.fraud_verification_data) {
            fraudAndTamperingValues.push({
              label: "Anti-Tampering and Fraud Verification",
              value: {
                verificationDescription: proofOfAddressDocument.fraud_verification_data.verification_description,
                verificationData: proofOfAddressDocument.fraud_verification_data.verification_data,
              },
              type: "doc_fraud_verification",
            });
          }

          if (!proofOfAddressDocument.visual_inspection) break;

          if (proofOfAddressDocument.visual_inspection.type === "VisualInspectionResults") {
            if (!proofOfAddressDocument.visual_inspection.visual_analysis) break;

            visualAnalysisValues.push({
              value: proofOfAddressDocument.visual_inspection.summary,
              label: "Summary",
              type: "summary",
              level: proofOfAddressDocument.visual_inspection.inspection_passed ? "success" : "failure",
            });

            visualAnalysisValues.push({
              value: proofOfAddressDocument.visual_inspection.visual_analysis.seal_verification,
              label: "Official Seal",
              type: "visual_inspection_result",
              level:
                proofOfAddressDocument.visual_inspection.visual_analysis.seal_verification.passed &&
                proofOfAddressDocument.visual_inspection.visual_analysis.seal_verification.present
                  ? "success"
                  : "failure",
            });

            visualAnalysisValues.push({
              value: proofOfAddressDocument.visual_inspection.visual_analysis.signature_verification,
              label: "Signature",
              type: "visual_inspection_result",
              level:
                proofOfAddressDocument.visual_inspection.visual_analysis.signature_verification.passed &&
                proofOfAddressDocument.visual_inspection.visual_analysis.signature_verification.present
                  ? "success"
                  : "failure",
            });
          } else if (
            "official_seal" in proofOfAddressDocument.visual_inspection &&
            "official_signature" in proofOfAddressDocument.visual_inspection
          ) {
            const valuesArray = Object.entries(proofOfAddressDocument.visual_inspection).map(([key, value]) => {
              return {
                label: snakeCaseToProperCase(key),
                value: value,
              };
            });

            visualAnalysisValues.push({
              value: valuesArray,
              label: "Visual Inspection Results",
              type: "validation_entries",
            });
          } else {
            visualAnalysisValues.push({
              value: {
                validStateSeal: proofOfAddressDocument.visual_inspection.seal_valid,
                validSignature: proofOfAddressDocument.visual_inspection.signature_valid,
              },
              label: "Visual Inspection Results",
              type: "doc_visual_inspection_result",
            });
          }
        }

        break;
      case EIN_DOCUMENT_CHECK_ID:
        const combinedEINDocuments = [
          ...(checkResult.check_result?.valid_ein_documents || []),
          ...(checkResult.check_result?.valid_documents || []),
          ...(checkResult.check_result?.invalid_documents || []),
        ];
        const einDocument = combinedEINDocuments.length > 0 ? combinedEINDocuments[0] : null;

        contentValues.push({
          value: checkResult.verified_business_name || einDocument?.business_name,
          label: "Business Name",
          type: "text",
        });
        contentValues.push({
          value: checkResult.verified_ein || einDocument?.ein,
          label: "EIN",
          type: "wrapped",
        });

        if (einDocument) {
          contentValues.push({
            value: einDocument.summary || einDocument.extraction_data.summary,
            label: "Summary",
            type: "text",
          });

          if (einDocument.fraud_verification_data) {
            fraudAndTamperingValues.push({
              label: "Anti-Tampering and Fraud Verification",
              value: {
                verificationDescription: einDocument.fraud_verification_data.verification_description,
                verificationData: einDocument.fraud_verification_data.verification_data,
              },
              type: "doc_fraud_verification",
            });
          }

          if (!einDocument.visual_inspection) break;

          if (einDocument.visual_inspection.type === "VisualInspectionResults") {
            if (!einDocument.visual_inspection.visual_analysis) break;

            visualAnalysisValues.push({
              value: einDocument.visual_inspection.summary,
              label: "Summary",
              type: "summary",
              level: einDocument.visual_inspection.inspection_passed ? "success" : "failure",
            });

            visualAnalysisValues.push({
              value: einDocument.visual_inspection.visual_analysis.seal_verification,
              label: "Official Seal",
              type: "visual_inspection_result",
              level:
                einDocument.visual_inspection.visual_analysis.seal_verification.passed &&
                einDocument.visual_inspection.visual_analysis.seal_verification.present
                  ? "success"
                  : "failure",
            });

            visualAnalysisValues.push({
              value: einDocument.visual_inspection.visual_analysis.signature_verification,
              label: "Signature",
              type: "visual_inspection_result",
              level:
                einDocument.visual_inspection.visual_analysis.signature_verification.passed &&
                einDocument.visual_inspection.visual_analysis.signature_verification.present
                  ? "success"
                  : "failure",
            });
          } else if (
            "official_seal" in einDocument.visual_inspection &&
            "official_signature" in einDocument.visual_inspection
          ) {
            const valuesArray = Object.entries(einDocument.visual_inspection).map(([key, value]) => {
              return {
                label: snakeCaseToProperCase(key),
                value: value,
              };
            });

            visualAnalysisValues.push({
              value: valuesArray,
              label: "Visual Inspection Results",
              type: "validation_entries",
            });
          } else {
            visualAnalysisValues.push({
              value: {
                validStateSeal: einDocument.visual_inspection.seal_valid,
                validSignature: einDocument.visual_inspection.signature_valid,
              },
              label: "Visual Inspection Results",
              type: "doc_visual_inspection_result",
            });
          }
        }
        break;
      case SOURCE_OF_FUNDS_CHECK_ID:
        contentValues.push({
          value: checkResult.verified_amount || "Not verified",
          label: "Amount",
          type: "text",
        });
        break;
      case KYC_PROOF_OF_ADDRESS_CHECK_ID:
        const combinedKycProofOfAddressDocuments = [...(checkResult.check_result?.invalid_documents || [])];
        const kycProofOfAddressDocument =
          combinedKycProofOfAddressDocuments.length > 0 ? combinedKycProofOfAddressDocuments[0] : null;
        contentValues.push({
          value: isAddressProvided(checkResult.check_result?.verified_address)
            ? checkResult.check_result?.verified_address
            : kycProofOfAddressDocument?.address,
          label: "Individual Address",
          type: "address",
        });
        break;
      default:
        break;
    }

    return {
      contentValues,
      visualAnalysisValues,
      fraudAndTamperingValues,
    };
  };

  const getProvidedInformation = () => {
    let values: Value[] = [];

    const inputData = checkResult.inputs;

    if (!inputData) return [];

    switch (checkId) {
      case INCORPORATION_DOCUMENT_CHECK_ID:
        values = [
          {
            value: inputData.registered_business_name,
            label: "Business Name",
            type: "text",
          },
          {
            value: inputData.incorporation_date
              ? format(new Date(inputData.incorporation_date?.replace(/-/g, "/")), "MMMM dd, yyyy")
              : null,
            label: "Incorporation Date",
            type: "text",
          },
          {
            value: inputData.business_registration_number,
            label: "Registration Number",
            type: "wrapped",
          },
          {
            value: inputData.incorporation_address,
            label: "Address of Incorporation",
            type: "address",
          },
        ];

        break;
      case PROOF_OF_ADDRESS_CHECK_ID:
        values = [
          {
            value: inputData.address,
            label: "Address of Operation",
            type: "address",
          },
        ];
        break;
      case KYC_PROOF_OF_ADDRESS_CHECK_ID:
        values.push({
          value: inputData.address,
          label: "Individual Address",
          type: "address",
        });
        break;
      case EIN_DOCUMENT_CHECK_ID:
        values.push({
          value: inputData.business_name,
          label: "Business Name",
          type: "text",
        });
        values.push({
          value: inputData.ein,
          label: "EIN",
          type: "wrapped",
        });
        break;
      case SOURCE_OF_FUNDS_CHECK_ID:
        values.push({
          value: checkResult.verified_amount || "Not verified",
          label: "Amount",
          type: "text",
        });
        break;
      case KYC_PROOF_OF_ADDRESS_CHECK_ID:
        values.push({
          value: checkResult.verified_address,
          label: "Address",
          type: "address",
        });
        break;
      default:
        break;
    }
    return values;
  };

  const handleContactSupportClick = () => {
    const subject = `Document Validation Failure - ${checkResult.checkName}`;
    const body = `I'm having trouble with the ${checkResult.checkName} check for my ${checkResult.documentType} document. The job URL is ${checkResult.jobUrl} and the file URL is ${checkResult.fileUrl}.`;

    window.open(`mailto:support@parcha.com?subject=${subject}&body=${body}`, "_blank");
  };

  const onContactSupportClickHandler = () => {
    const subject = `Error in job ${jobId} for the ${checkName?.trim()} check`;
    const body = `There was an error in job with ID ${jobId} for the ${checkName?.trim()} check.`;
    const mailtoLink = `mailto:support@parcha.ai?subject=${encodeURIComponent(subject)}&body=${encodeURIComponent(
      body || "",
    )}`;
    window.open(mailtoLink, "_blank");
  };

  const statusEvents = checkResult?.status_messages?.filter((message: any) => message.event === "status");
  const lastStatusMessage = statusEvents && statusEvents[statusEvents.length - 1];

  const isPdf = documentName?.toLowerCase().endsWith(".pdf");

  const { contentValues, visualAnalysisValues, fraudAndTamperingValues } = getValues();

  return (
    <div className="flex h-full text-xs">
      <div className="w-1/4 h-[calc(100%-47px)] border-r bg-white overflow-y-auto">
        {checkResult.passed !== null ? (
          <div
            className={`text-base flex flex-col gap-y-2 px-4 py-4 ${
              checkResult.passed ? "bg-green-100 text-green-700" : "bg-red-100 text-red-700"
            }`}
          >
            <div
              className={`flex items-center gap-x-2 ${
                checkResult.passed ? "bg-green-100 text-green-700" : "bg-red-100 text-red-700"
              }`}
            >
              <button onClick={() => setShowAnswer(!showAnswer)} className="inline-flex items-center">
                {showAnswer ? (
                  <ExpandLessRounded sx={{ fontSize: "1.125rem" }} />
                ) : (
                  <ExpandMoreRounded sx={{ fontSize: "1.125rem" }} />
                )}
              </button>
              {checkResult.passed ? (
                <CheckCircleRounded sx={{ fontSize: "1rem" }} />
              ) : (
                <CancelRounded sx={{ fontSize: "1rem" }} />
              )}
              <span className="font-semibold">{checkResult.passed ? "Pass" : "Fail"}</span>
            </div>
            {showAnswer && <p className="text-xs">{checkResult.answer}</p>}
          </div>
        ) : lastStatusMessage ? (
          <div className="p-4">
            <CheckStatusMessage messageContents={{ status: lastStatusMessage.content.status }} />
          </div>
        ) : null}
        <div>
          <div className="flex flex-wrap items-center justify-between gap-x-4 border-b border-solid border-slate-200 p-4">
            <span className="text-base font-semibold">{checkName}</span>
          </div>
          <div className="space-y-0 divide-y divide-solid divide-slate-200">
            <div className="flex flex-col gap-y-2 p-4">
              <>
                <button onClick={() => setShowContent(!showContent)} className="inline-flex items-center gap-x-2">
                  {showContent ? (
                    <ExpandLessRounded sx={{ fontSize: "1.125rem" }} />
                  ) : (
                    <ExpandMoreRounded sx={{ fontSize: "1.125rem" }} />
                  )}
                  <h3 className="text-sm font-semibold">Content Verification</h3>
                </button>
              </>
              {showContent && (
                <>
                  <FullWidthTabs
                    tabs={[
                      {
                        name: "Found Information",
                        id: "found-information",
                        count: 0,
                      },
                      {
                        name: "Provided Information",
                        id: "provided-information",
                        count: 0,
                      },
                    ]}
                    selectedTab={selectedTab}
                    onTabChangeHandler={(tabId: string) => setSelectedTab(tabId)}
                    tabLabelClassName="text-[0.875rem] font-normal"
                  />
                  <div className="space-y-4 ">
                    {selectedTab === "found-information" ? (
                      checkResult.status === "error" ? (
                        <div className=" flex flex-col justify-center items-center w-full p-2 md:p-5 lg:p-8 text-sm gap-y-2 bg-slate-50 rounded-[1.25rem]">
                          <ErrorRounded sx={{ fontSize: "2rem" }} className="text-red-600" />
                          <div className="text-center">
                            <p className="text-xs lg:text-sm font-semibold lg:font-bold text-slate-700">
                              An error has occurred
                            </p>
                            <p className="text-slate-500 text-xs leading-5">We apologize for the inconvenience.</p>
                            <p className="text-slate-500 text-xs leading-5">
                              Our team has been notified and will look into it as soon as possible.
                            </p>
                            <p className="mt-4 text-xs text-slate-500">
                              If the problem persists please contact support.
                            </p>
                            <button
                              type="button"
                              className="mt-4 inline-flex items-center gap-x-2 text-slate-900 px-3 py-1.5 rounded-md bg-white border border-solid border-slate-300 shadow-sm"
                              onClick={onContactSupportClickHandler}
                            >
                              <EmailRounded sx={{ fontSize: "1rem" }} className="mr-1 h-4 w-4" aria-hidden="true" />
                              <span className="line-clamp-1 text-xs">Contact Support</span>
                            </button>
                          </div>
                        </div>
                      ) : (
                        contentValues.map((value: any, index: any) => (
                          <ValueContainer
                            key={index}
                            value={value.value}
                            label={value.label}
                            type={value.type}
                            checkStatus={checkResult.status}
                            level="normal"
                          />
                        ))
                      )
                    ) : null}

                    {selectedTab === "provided-information" &&
                      getProvidedInformation().map((value: any, index: any) => {
                        return (
                          <ValueContainer
                            key={index}
                            value={value.value}
                            label={value.label}
                            type={value.type}
                            level="normal"
                            checkStatus={checkResult.status}
                            isProvidedInformation
                          />
                        );
                      })}
                  </div>
                </>
              )}
            </div>
            {checkResult.status === "complete" && (
              <>
                {visualAnalysisValues.length > 0 && (
                  <div className="flex flex-col gap-y-2 p-4">
                    <>
                      <button
                        onClick={() => setShowVisualAnalysis(!showVisualAnalysis)}
                        className="inline-flex items-center gap-x-2"
                      >
                        {showVisualAnalysis ? (
                          <ExpandLessRounded sx={{ fontSize: "1.125rem" }} />
                        ) : (
                          <ExpandMoreRounded sx={{ fontSize: "1.125rem" }} />
                        )}
                        <h3 className="text-sm font-semibold">Visual Analysis</h3>
                      </button>
                    </>
                    {showVisualAnalysis && (
                      <div className="space-y-4">
                        {visualAnalysisValues.map((value: any, index: any) => (
                          <ValueContainer
                            key={index}
                            value={value.value}
                            label={value.label}
                            type={value.type}
                            checkStatus={checkResult.status}
                            level={value.level || "normal"}
                          />
                        ))}
                      </div>
                    )}
                  </div>
                )}
                {fraudAndTamperingValues.length > 0 && (
                  <div className="flex flex-col gap-y-2 p-4">
                    <>
                      <button
                        onClick={() => setShowFraudAndTampering(!showFraudAndTampering)}
                        className="inline-flex items-center gap-x-2"
                      >
                        {showFraudAndTampering ? (
                          <ExpandLessRounded sx={{ fontSize: "1.125rem" }} />
                        ) : (
                          <ExpandMoreRounded sx={{ fontSize: "1.125rem" }} />
                        )}
                        <h3 className="text-sm font-semibold">Fraud and Tampering</h3>
                      </button>
                    </>
                    {showFraudAndTampering && (
                      <div className="space-y-4">
                        {fraudAndTamperingValues.map((value: any, index: any) => (
                          <ValueContainer
                            key={index}
                            value={value.value}
                            label={value.label}
                            type={value.type}
                            checkStatus={checkResult.status}
                            level="normal"
                          />
                        ))}
                      </div>
                    )}
                  </div>
                )}
              </>
            )}
          </div>
        </div>
      </div>
      <div className="h-full flex flex-1">
        <div className="flex flex-col h-[calc(100%-3.5rem)] w-full gap-y-4 w-3/4 rounded-md">
          {isPdf && (
            <div className="h-[90%]">
              <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.11.174/build/pdf.worker.min.js">
                <Viewer
                  transformGetDocumentParams={(options) => ({ ...options, isEvalSupported: false })}
                  plugins={[
                    thumbnailPluginInstance,
                    zoomPluginInstance,
                    pageNavigationPluginInstance,
                    fullScreenPluginInstance,
                  ]}
                  fileUrl={documentUrl}
                  onDocumentLoad={() => setPdfError(false)}
                  renderError={() => {
                    setPdfError(true);
                    return (
                      <div className="flex items-center justify-center h-full">
                        <div className="flex flex-col gap-y-5 p-10 items-center bg-slate-100 rounded-lg max-w-[25rem]">
                          <ErrorRounded sx={{ fontSize: "2.25rem" }} className="text-red-600" />
                          <div className="flex flex-col justify-center items-center text-slate-700">
                            <h3 className="text-sm font-semibold">We were unable to load the document</h3>
                            <h4 className="text-xs">We apologize for the inconvenience.</h4>
                          </div>
                          <p className="text-xs text-center text-slate-500">
                            If the problem persists, please contact support and we will look into the issue as soon as
                            possible.
                          </p>
                          <button
                            onClick={handleContactSupportClick}
                            className="inline-flex items-center gap-x-2 text-slate-900 px-3 py-1.5 rounded-md bg-white border border-solid border-slate-300 shadow-sm"
                          >
                            <MailRounded sx={{ fontSize: "1rem" }} />
                            <span>Contact Support</span>
                          </button>
                        </div>
                      </div>
                    );
                  }}
                />
              </Worker>
            </div>
          )}
          {!isPdf && <img src={documentUrl} alt={documentName} className="h-full w-full object-contain" />}
          {isPdf && !pdfError && (
            <div className="flex h-[10%] p-4 items-center justify-center gap-x-2 border-t border-solid border-slate-200">
              <ZoomOut>
                {(props) => (
                  <button
                    {...props}
                    className="border border-solid border-slate-300 rounded-md text-slate-900 px-1 py-0.5"
                  >
                    <ZoomOutRounded sx={{ fontSize: "1.125rem" }} />
                  </button>
                )}
              </ZoomOut>{" "}
              <ZoomIn>
                {(props) => (
                  <button
                    {...props}
                    className="border border-solid border-slate-300 rounded-md text-slate-900 px-1 py-0.5"
                  >
                    <ZoomInRounded sx={{ fontSize: "1.125rem" }} />
                  </button>
                )}
              </ZoomIn>
              <div className="flex items-center gap-x-1">
                Page <CurrentPageLabel /> of <NumberOfPages />
              </div>
              <EnterFullScreen>
                {(props) => (
                  <button
                    {...props}
                    className="border border-solid border-slate-300 rounded-md text-slate-900 px-1 py-0.5"
                  >
                    <FullscreenRounded sx={{ fontSize: "1.125rem" }} />
                  </button>
                )}
              </EnterFullScreen>
              <a
                href={documentUrl}
                target="_blank"
                rel="noopener noreferrer"
                className="border border-solid border-slate-300 rounded-md text-slate-900 px-1 py-0.5"
              >
                <LaunchRounded sx={{ fontSize: "1.125rem" }} />
              </a>
            </div>
          )}
        </div>
        {isPdf && !pdfError && (
          <div className="h-full w-1/4 bg-slate-100 border-l border-solid border-slate-200">
            <Thumbnails renderThumbnailItem={renderThumbnailItem} />
          </div>
        )}
      </div>
    </div>
  );
};

const ValueContainer = ({ value, label, type, level, checkStatus, isProvidedInformation }: any) => {
  const getValue = () => {
    if (!value && !["complete", "error"].includes(checkStatus) && !isProvidedInformation) {
      return <Skeleton width="w-full" height="h-4" shape="rounded" />;
    }

    if (type === "address") {
      return value &&
        isAddressProvided({
          street1: value.street_1,
          street2: value.street_2,
          city: value.city,
          state: value.state,
          countryCode: value.country_code,
          postalCode: value.postal_code,
        }) ? (
        <AddressValue
          street1={value.street_1}
          street2={value.street_2}
          city={value.city}
          state={value.state}
          countryCode={value.country_code}
        />
      ) : isProvidedInformation ? (
        <span className="text-slate-400 italic">Not provided</span>
      ) : null;
    } else if (type === "wrapped") {
      return value?.length > 0 ? (
        <WrappedValue text={value} level={level} />
      ) : isProvidedInformation ? (
        <span className="text-slate-400 italic">Not provided</span>
      ) : null;
    } else if (type === "owner_info") {
      return value?.map((owner: any) => (
        <div key={owner.full_name} className="flex flex-col">
          <span className="uppercase">{owner.full_name}</span>
          {owner.percentage === null ? (
            <InfoValue label="Percent Ownership" value="Unknown" />
          ) : typeof owner.percentage === "number" ? (
            <InfoValue
              label="Percent Ownership"
              value={`${
                owner.percentage >= 0 && owner.percentage <= 1
                  ? (owner.percentage * 100).toFixed(0)
                  : owner.percentage % 1 === 0
                    ? Math.round(owner.percentage)
                    : owner.percentage.toFixed(2)
              }%`}
            />
          ) : null}
          {owner.shares === null ? (
            <InfoValue label="Shares" value="Unknown" />
          ) : (
            typeof owner.shares === "number" && <InfoValue label="Shares" value={`${owner.shares.toLocaleString()}`} />
          )}
        </div>
      ));
    } else if (type === "validation_entries") {
      return value.map((entry: any) => (
        <div key={entry.label} className="flex flex-col gap-2">
          <h2 className="font-semibold text-xs">{entry.label}</h2>
          <ul className="list-disc list-inside ml-2">
            {entry.value.present ? (
              <li className="font-semibold">Found</li>
            ) : (
              <li className="italic text-slate-500">Not found</li>
            )}
            {entry.value.valid ? (
              <li className="font-semibold">Valid</li>
            ) : entry.value.present ? (
              <li className="italic text-slate-500">Not Valid</li>
            ) : null}
          </ul>
          {entry.value.inspection_summary && <p>{entry.value.inspection_summary}</p>}
        </div>
      ));
    } else if (type === "visual_inspection_result") {
      return (
        <div className="flex flex-col gap-4">
          {value.present ? `${label} is present` : `${label} is not present`}
          <p className={twJoin(value.passed ? "text-slate-500" : "text-yellow-800")}>{value.analysis}</p>
        </div>
      );
    } else if (type === "doc_fraud_verification") {
      return (
        <div className="flex flex-col gap-[16px]">
          <p>{value.verificationDescription}</p>
          {value.verificationData && value.verificationData.length > 0 ? (
            <div className="flex flex-col gap-[10px]">
              {value.verificationData.map((verificationData: any, index: number) => {
                return (
                  <div
                    key={`${verificationData.title}-${index}`}
                    className={twJoin(
                      "flex flex-col gap-[10px] border border-solid p-2 rounded-md",
                      verificationData.type === "RISK" ? "border-yellow-600" : "",
                      verificationData.type === "INFO" ? "border-slate-500" : "",
                      verificationData.type === "TRUST" ? "border-green-600" : "",
                    )}
                  >
                    <div className="flex items-baseline justify-between gap-2">
                      <span className="text-sm font-semibold">{verificationData.title}</span>
                      {verificationData.type === "RISK" ? (
                        <WarningRounded sx={{ fontSize: "1rem" }} className="text-yellow-600" />
                      ) : verificationData.type === "INFO" ? (
                        <InfoRounded sx={{ fontSize: "1rem" }} className="text-slate-500" />
                      ) : verificationData.type === "TRUST" ? (
                        <CheckCircleRounded sx={{ fontSize: "1rem" }} className="text-green-600" />
                      ) : null}
                    </div>
                    {verificationData.category && (
                      <span className="w-fit px-3 py-1 border border-slate-200 bg-slate-50 rounded-full text-xs text-slate-700">
                        {snakeCaseToProperCase(verificationData.category)}
                      </span>
                    )}
                    <p>{verificationData.description}</p>
                  </div>
                );
              })}
            </div>
          ) : (
            <span className="italic text-slate-500">
              There was no indication of tampering or fraud with the provided document.
            </span>
          )}
        </div>
      );
    } else if (type === "summary") {
      return <p className="text-slate-900">{value}</p>;
    } else {
      return value ? (
        <p>{value}</p>
      ) : isProvidedInformation ? (
        <span className="text-slate-400 italic">Not provided</span>
      ) : null;
    }
  };

  return (
    <div
      className={twJoin(
        "p-3 text-slate-700 rounded-lg flex flex-col gap-y-2",
        level === "failure" && type !== "summary" ? "border border-yellow-600 bg-white" : "",
        type === "summary" ? (level === "success" ? "bg-green-50" : "bg-red-50") : "",
        isProvidedInformation
          ? "bg-white border border-solid border-transparent"
          : !["doc_fraud_verification", "summary"].includes(type)
            ? "bg-slate-50 border border-solid border-slate-50"
            : "",
      )}
    >
      <h4
        className={twJoin(
          "font-semibold flex items-center gap-x-1 justify-between",
          checkStatus !== "complete" && !isProvidedInformation ? shimmerEffectClassNames : "",
        )}
      >
        {label}
        {level === "success" && <CheckCircleRounded sx={{ fontSize: "1rem" }} className="text-green-600" />}
        {level === "failure" ? (
          type === "summary" ? (
            <CancelRounded sx={{ fontSize: "1rem" }} className="text-red-600" />
          ) : type === "visual_inspection_result" ? (
            <WarningRounded sx={{ fontSize: "1rem" }} className="text-yellow-600" />
          ) : null
        ) : null}
      </h4>
      {getValue() || <span className="text-slate-400 italic">{`No ${label} found`}</span>}
    </div>
  );
};

export default DocumentValidation;
