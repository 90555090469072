import { format } from "date-fns";
import { twJoin } from "tailwind-merge";

type FeedbackStatusProps = {
  feedback: any;
  showEventInfo: boolean;
  showFeedbackComment: boolean;
  setShowFeedbackComment: (value: boolean) => void;
  flaggedSelected: boolean;
  setFlaggedSelected: (value: boolean) => void;
  setFeedbackComment: (value: string) => void;
};

const FeedbackStatus = ({
  feedback,
  showEventInfo,
  showFeedbackComment,
  setShowFeedbackComment,
  flaggedSelected,
  setFlaggedSelected,
  setFeedbackComment,
}: FeedbackStatusProps) => {
  return (
    <div
      className={twJoin(
        "flex items-center lg:justify-end gap-x-2 w-full text-xs text-slate-500 truncate",
        showEventInfo ? "" : "rounded-b-lg",
      )}
    >
      <button
        className="underline text-brand-purple"
        type="button"
        onClick={(e) => {
          e.preventDefault();
          e.stopPropagation();
          if (showFeedbackComment) {
            setShowFeedbackComment(false);
          } else if (!flaggedSelected) {
            setShowFeedbackComment(true);
            setFeedbackComment(feedback.comment);
          } else {
            setFlaggedSelected(false);
          }
        }}
      >
        {showFeedbackComment || flaggedSelected ? "Hide Feedback" : "View Feedback"}
      </button>
    </div>
  );
};

export default FeedbackStatus;
